




import { Component, Vue } from 'vue-property-decorator';
import { store } from '@/store';

const routeGuardMaintenance = async (to, from, next) => {
  if (!store.getters['api/techSupportGranted']) {
    next('/main');
  } else {
    next();
  }
};

@Component
export default class Maintenance extends Vue {
  public beforeRouteEnter(to, from, next) {
    routeGuardMaintenance(to, from, next);
  }
  public beforeRouteUpdate(to, from, next) {
    routeGuardMaintenance(to, from, next);
  }
}
